<script>
	export let addClass = ''
	export let align = 'center'
	export let marginTop = '40px'
	export let onlyDesktop = false
</script>

<img
	class="red-block {addClass} {align}"
	src="/images/icons/red-block.svg"
	alt="Separátor"
	class:only-desktop={onlyDesktop}
	style:margin-top={marginTop}
/>

<style lang="scss">
	.red-block {
		display: block;

		&.center {
			margin: 0 auto 20px;
		}

		&.left {
			margin: 0 0 20px 0;
		}

		@media only screen and (max-width: 760px) {
			margin: 40px auto 20px !important;
		}
	}
</style>
